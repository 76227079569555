module services {
    export module customs {
        export class dutyCalculatorService implements interfaces.customs.IDutyCalculatorService {
            static $inject = ["$resource", "ENV", "$http", "$timeout", "generalService"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig, private $http: ng.IHttpService,
                private $timeout: ng.ITimeoutService, public generalService: interfaces.applicationcore.IGeneralService) {
            }

            saveToDocument(id: number, IsLexisNexis: number): ng.IPromise<void> {
                var pdfDownload = document.createElement("a");
                document.body.appendChild(pdfDownload);

                var url = this.ENV.DSP_URL + 'DutyCalculator/SaveToDocument?';

                url += 'id=' + id + '&ISLexisNexis=' + IsLexisNexis;

                return this.$http({
                    method: 'GET',
                    responseType: 'arraybuffer',
                    url: url,
                }).then((response) => {
                    var filename = response.headers()['x-filename'] || 'DutyCalculation.pdf';
                    var fileBlob = new Blob([response.data as BlobPart], { type: 'application/octet-stream' });

                    if (navigator.appVersion.toString().indexOf('.NET') > 0) { // for IE browser
                        window.navigator.msSaveBlob(fileBlob, filename);
                    } else { // for other browsers
                        var fileURL = window.URL.createObjectURL(fileBlob);
                        pdfDownload.href = fileURL;
                        pdfDownload.download = filename;
                        pdfDownload.click();
                        pdfDownload.remove();
                    }

                    this.$timeout(() => {
                        this.generalService.displayMessage("Your download has started.", Enum.EnumMessageType.Success);
                    });
                }).catch((errorResponse) => {
                    var fileBlob = new Blob([errorResponse.data], { type: 'application/json' });
                    var fileURL = window.URL.createObjectURL(fileBlob);
                    const reader = new FileReader();

                    // This fires after the blob has been read/loaded.
                    reader.addEventListener('loadend', (e: ProgressEvent) => {
                        const text = e.target['result'];

                        this.$timeout(() => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>JSON.parse(text));
                        });
                    });

                    // Start reading the blob as text.
                    reader.readAsText(fileBlob);
                });
            }

            CalculateDuty(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler>
            {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "DutyCalculator/CalculateDuty", {
                    dutyCalculator: "@dutyCalculator",
                }, {
                        save: {
                            method: 'POST',
                            isArray: false,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates }
                        }
                    });
            }


            GetDutyCalculation(dutyCalculatorId: number): ng.resource.IResourceClass<interfaces.customs.IDutyCalculation> {
                return this.$resource<interfaces.customs.IDutyCalculation>(this.ENV.DSP_URL + "DutyCalculator/GetDutyCalculation", {
                    dutyCalculatorId: dutyCalculatorId
                }, {
                        query: {
                            method: 'GET',
                            isArray: false,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates }
                        }
                    });
            }

            GetDeclarationCountryCurrency(declarationCountryId: number): ng.resource.IResourceClass<interfaces.applicationcore.ICurrencyViewModel> {
                return this.$resource<interfaces.applicationcore.ICurrencyViewModel>(this.ENV.DSP_URL + "DutyCalculator/GetDeclarationCountryCurrency", {
                    declarationCountryId: declarationCountryId
                }, {
                        query: {
                            method: 'GET',
                            isArray: false,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates }
                        }
                    });
            }


            GetHSCodeDescription(): ng.resource.IResourceClass<interfaces.customs.DutyCalculationHSCode> {
                return this.$resource<interfaces.customs.DutyCalculationHSCode> (this.ENV.DSP_URL + "DutyCalculator/GetHSCodeDescription", {
                    dutyCalculationHSCode: "@dutyCalculationHSCode"
                }, {
                        save: {
                            method: 'POST',
                            isArray: false,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates }
                        }
                    });
            }

            SetupDutyCalculator(): ng.resource.IResourceClass<interfaces.customs.IDutyCalculation>
            {
                return this.$resource<interfaces.customs.IDutyCalculation>(this.ENV.DSP_URL + "DutyCalculator/SetupDutyCalculator", {
                    declarationCountryId: "@declarationCountryId",
                    tariffCode: "@tariffCode",
                    simulationDate: "@simulationDate",
                    countryOfOriginId: "@countryOfOriginId",
                    customsValue: "@customsValue",
                }, {
                    query: {
                        method: 'GET',
                        isArray: false,
                        interceptor: { response: config.appResourceDateInterceptor.parseResponseDates }
                    }
                });
            }
        }
    }
    angular.module("app").service("dutyCalculatorService", services.customs.dutyCalculatorService);
}